<template>
  <div style="position:absolute;z-index: 20002;">
     <el-drawer
      v-model="_drawer"
      size="70%"
      title="高级检索语法说明"
      custom-class="syntax-explain"
    >
      <div class="field_content" v-for="(item, index) in tableData" :key="index">
        <p class="title">{{`${index+1}、${item.label}`}}</p>
        <span class="tip">{{item.tip}}</span>
        <div v-for="(titem, tid) in item.data" :key="tid" style="width: 100%">
          <el-table v-if="Array.isArray(titem)" :data="titem" :header-cell-style="{ backgroundColor: $isDark() ? '#3f4251' : '#f6f8fa' }">
            <el-table-column prop="symbol" :label="index ? '运算符' : '分类'" min-width="1" />
            <el-table-column prop="explain" label="说明" min-width="3" />
            <el-table-column prop="example" label="搜索示例" min-width="3">
              <template #default="scope">
                <div v-for="(examit, examid) in scope.row.example" :key="examid">
                  <span>{{examit}}</span>
                  <copy-icon
                    style="vertical-align: middle;width:20px;color:var(--color-text-3);font-size:16px;"
                    :right="8"
                    :copyText="examit"
                    :name="`keyword${index}${tid}${examid}`"
                  />
                  <el-tooltip :effect="$isDark() ? 'dark' : 'light'" content="检索" placement="top">
                    <svg-icon name="sousuo" style="color:#00ab7a;cursor:pointer;" @click="search(examit)"></svg-icon>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="result" label="搜索结果" min-width="4">
              <template #default="scope">
                <span v-html="scope.row.result"></span>
              </template>
            </el-table-column>
          </el-table>
          <span v-else @click="xxx" class="explain">{{titem.explain}}</span>
        </div>
        <span class="explain">{{item.explain}}</span>
      </div>
    </el-drawer>
  </div>
</template>

<script setup>
import { defineProps, computed, defineEmits } from 'vue'
const props = defineProps({
  drawer: {
    type: Boolean,
    default: false
  },
  _id: {
    type: String,
    default: ''
  }
})
const emits = defineEmits(['update:drawer', 'addField'])
const _drawer = computed({
  get: () => props.drawer,
  set: (val) => {
    emits('update:drawer')
  }
})
const search = v => {
  _drawer.value = false
  emits('addField', v)
}
const tableData = [
  {
    label: '字段查询',
    data: [
      [
        {
          symbol: '单个字段单个值查询',
          explain: ['搜索单个字段的单个值需要在值两边增加双引号，不然可能搜索内容会被分词。'],
          example: ['tagzhcn:"勒索"'],
          result: '搜索中文标签为勒索的报告'
        }
      ]
    ]
  },
  {
    label: '通配符查询',
    data: [
      [
        {
          symbol: '?',
          explain: ['通配符问号（?），表示代替一个字符'],
          example: ['name:"w?n.dat"'],
          result: '搜索结果文件名称为win.dat、wan.dat、wsn.dat的任务'
        },
        {
          symbol: '*',
          explain: ['通配符星号（*），表示任意数量的字符，包括无'],
          example: ['name: "w*svcs.exe"'],
          result: '搜索结果为任务名称为winsvcs.exe、wisvcs.exe的任务'
        }
      ]
    ]
  },
  {
    label: '范围查询',
    tip: '可以为数字字段指定范围',
    data: [
      [
        {
          symbol: '{min TO max}',
          explain: ['范围搜索，尖括号表示不包含括号内的范围值，最小值在最前面，最大值在最后面，两个值之间需要加TO'],
          example: ['score:{8 TO 10}'],
          result: '搜索的报告动态分值大于8分小于10分'
        },
        {
          symbol: '[min TO max]',
          explain: ['范围搜索，方括号表示包含括号内的范围值，最小值在最前面，最大值在最后面，两个值之间需要加TO'],
          example: ['score:[8 TO 10]'],
          result: '搜索的报告动态分值大于等于8分小于等于10分'
        }
      ],
      {
        explain: '混合使用:方括号和尖括号可以混合使用，比如score:{7.5 TO 10],搜索结果为查询的报告动态分值大于7.5分小于等于10分；score:[7.5 TO 10}搜索结果为查询的报告动态分值大于等于7.5分小于10分'
      },
      [
        {
          symbol: '>',
          explain: ['大于号(英文输入)，用于查询大于某数值'],
          example: ['score:>8'],
          result: '搜索动态分值大于8分的报告'
        },
        {
          symbol: '>=',
          explain: ['大于等于号(英文输入)，用于查询大于等于某数值'],
          example: ['score:>=8'],
          result: '搜索动态分值大于等于8分的报告'
        },
        {
          symbol: '<',
          explain: ['小于号(英文输入)，用于查询小于某数值'],
          example: ['score:<8'],
          result: '搜索动态分值小于8分的报告'
        },
        {
          symbol: '<=',
          explain: ['小于等于号(英文输入)，用于查询小于等于某数值'],
          example: ['score:<=8'],
          result: '搜索动态分值小于等于8分的报告'
        }
      ]
    ]
  },
  {
    label: '布尔运算符查询',
    data: [
      [
        {
          symbol: 'AND',
          explain: '"与"连接两个关键词，相当于关键词的集合，符号"&&"可以代替单词AND',
          example: ['tagzhcn:"勒索" AND score:>7.5', 'tagzhcn:"勒索" &&tagzhcn:"木马"'],
          result: '搜索动态分值大于7.5分，且需要包含标签为勒索的报告，包含其中一个条件结果不会被返回'
        },
        {
          symbol: 'OR',
          explain: '"或"是默认运算符，若两个字段之间没有运算符则默认是"或",符号"||"可以代替"或"',
          example: ['tagzhcn:"勒索" OR score:>7.5', 'tagzhcn:"勒索" || score:>7.5'],
          result: '搜索动态分值大于7.5分或者中文标签是勒索的任务'
        },
        {
          symbol: 'NOT',
          explain: '不包含（不是），符号 ! 可用于代替单词 NOT',
          example: ['NOT qex_type:"dll32"', '!qex_type:"dll32'],
          result: '查询任务类型不是dll32的任务'
        },
        {
          symbol: '( )',
          explain: '括号，用于提升括号内查询的优先级',
          example: ['(tagzhcn:"勒索" AND score:7.5 ) OR score_combined:"7.5"'],
          result: '查询的报告需包含下列条件之一就可以被返回 <ul><li>命中标签为勒索且动态分值为7.5</li><li>报告分值为7.5</li></ul>'
        }
      ]
    ]
  },
  {
    label: '转义字符',
    tip: '若特殊字符需要作为普通字符，则需要进行转义，支持转义的字符：&& || > < ! ( ) { } [ ] " *? : \\ / 转义需要在字符段前后使用反引号``，若搜索命令行C:\\Windows\\Explorer.EXE，则需要写成`C:\\Windows\\Explorer.EXE`'
  }
]
</script>
<style lang="scss" scoped>
.field-item {
  width: 100%;
  p {
    color: var(--color-text-1);
    font-weight: 500;
  }
}
.field_content {
  margin-bottom: 30px;
  .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: var(--color-text-2);
    margin-top: 0;
  }
  .tip, .explain {
    font-size: 12px;
    color: var(--color-text-2);
    margin-bottom: 12px;
    display: block;
  }
  .explain {
    margin-top: 12px;
  }
  .tip {
    margin: 12px 0;
  }
}
:deep(.syntax-explain) {
  .el-drawer__header {
    font-weight: bold;
  }
}
</style>
