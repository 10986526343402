<template>
  <div>
    <network-vue :loading="loading" :networkData="networkData" :networkCount="props.count"/>
    <process-vue :loading="loading" :hasPermission="hasPermission" :processData="processData"/>
    <event-vue :loading="loading" :hasPermission="hasPermission" :eventData="eventData"/>
    <dropped-vue :loading="loading" :droppedData="droppedData"/>
    <dropped-vue type="memdump" :loading="loading" :droppedData="memdumpData"/>
  </div>
</template>

<script setup>
import NetworkVue from './module/network.vue'
import ProcessVue from './module/process.vue'
import EventVue from './module/event.vue'
import DroppedVue from './module/dropped.vue'
import { getAdSearchModuleOverview } from 'services/search'
import { defineProps, ref } from 'vue'
const props = defineProps({
  count: {
    type: Object,
    default: () => {}
  },
  record_id: {
    type: String,
    default: () => ''
  }
})
const networkData = ref({})
const loading = ref(true)
const processData = ref()
const eventData = ref()
const droppedData = ref()
const memdumpData = ref()
const hasPermission = ref(true)
getAdSearchModuleOverview({
  record_id: props.record_id
}).then(res => {
  loading.value = false
  if (res.code === 4020) {
    hasPermission.value = false
    return
  }
  networkData.value.hosts = res.network_hosts
  networkData.value.dns = res.domains.map(item => {
    return {
      area_name: item.request,
      response: item.answers.map(item => `${item.type} ${item.data}`).join('\n'),
      analyse: item.ip
    }
  })
  networkData.value.http = res.http_info
  networkData.value.https = res.https_info
  processData.value = res.behavior_info
  eventData.value = res.event_info || []
  droppedData.value = res?.dropped_info || []
  memdumpData.value = res?.memdump_info || []
})
</script>
<style lang="scss" scoped>

</style>
