/* eslint-disable */
<template>
  <div v-for="(currentItem, index) in processData" :key="index">
    <div class="detail-name">
      <img v-if="isDetail" class="detail-icon" :src="$img('search/process.svg')" alt="">
      <span v-copy="'process_name'">{{currentItem.process_name}}</span>
    </div>
    <div class="progress-analyse-summary-main">
      <div class="progress-analyse-summary">
        <div class="progress-analyse-summary-left">
          <dashboard :combined="currentItem.score"></dashboard>
        </div>
        <div class="progress-analyse-summary-right">
          <div class="summary-content">
            <div v-for="(item, key) in summaryKeyMap.content"
                :key="key"
                :class="[`summary-content-${key}`]">
              <div v-for="(item2, index) in item"
                  :key="index"
                  :class="[`summary-content-${key}-item`]">
                <span class="content-label">{{item2}}</span>
                <span class="content-value" v-if="index === 'process_type'">{{process_type(currentItem)}}</span>
                <span class="content-value" v-else-if="index === 'integrity_sid'" v-copy="'integrity_sid'">{{currentItem[index]}}</span>
                <span class="content-value" v-else>{{index === 'first_seen' ? dateFormat(currentItem[index]) : currentItem[index]}}</span>
              </div>
            </div>
          </div>
          <div class="summary-footer">
            <div v-for="(item, key) in summaryKeyMap.footer"
                :class="[`summary-footer-${key}`]"
                :key="key">
              <span class="footer-label">{{item}}</span>
              <span class="footer-value" v-copy="key">{{currentItem[key] || '-'}}</span>
            </div>
          </div>
          <div class="summary-parent">
            <div v-for="(item, key) in summaryKeyMap.parent"
                :key="key"
                :class="[`summary-parent-${key}`]">
              <div v-for="(item2, index) in item"
                  :key="index"
                  :class="[`summary-parent-${key}-item`]">
                <span class="parent-label" :class="{'parent-label-en': !$isZh()}" >{{item2}}</span>
                <span v-if="index !== 'ppid'" v-copy="index" class="parent-value">{{currentItem[index]}}</span>
                <span v-else class="parent-value">{{currentItem[index]}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-behavior" v-if="gendata(currentItem)?.childrenData?.length">
        <BehaviorSignature :data="gendata(currentItem)" />
      </div>
    </div>
  </div>
</template>

<script>
import { t, isZh } from 'app/i18n'
import dayjs from 'dayjs'
import dashboard from '@/components/echarts/dashboard'
import BehaviorSignature from '@/components/report/behaviorSignature'
import legendList from 'utils/lib/treeIconList'

export default {
  name: 'processCard',
  components: {
    dashboard,
    BehaviorSignature
  },
  props: {
    processData: {
      type: Object,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    isDetail: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      summaryKeyMap: {
        content: {
          left: {
            pid: t('report.behavior.summary.pid'),
            integrity_sid: t('report.behavior.summary.integrity_sid')
          },
          center: {
            username: t('report.behavior.summary.username'),
            process_type: t('report.behavior.summary.process_type')
          },
          right: {
            first_seen: t('report.behavior.summary.first_seen')
          }
        },
        footer: {
          process_path: t('report.behavior.summary.process_path'),
          user_sid: t('report.behavior.summary.user_sid'),
          command_line: t('report.behavior.summary.command_line')
        },
        parent: {
          left: {
            ppid: t('report.behavior.summary.ppid'),
            parent_name: t('report.behavior.summary.parent_name')
          },
          right: {
            parent_path: t('report.behavior.summary.parent_path'),
            parent_cmdline: t('report.behavior.summary.parent_cmdline')
          }
        }
      },
      currentProcessBehavior: {
        title: undefined,
        childrenData: []
      }
    }
  },
  methods: {
    dateFormat (sec) {
      if (!sec) return '-'
      const milliseconds = Math.floor(sec) * 1000
      return dayjs(milliseconds).format('YYYY-MM-DD HH:mm:ss')
    },
    process_type(v) {
      const path = v.process_path
      let type = ''
      if (path) {
        for (let k = 0; k < legendList.length; k++) {
          if (path.match(legendList[k].reg)) {
            type = legendList[k].name
            break
          } else {
            type = t('report.behavior.relation.tree_icon.other')
          }
        }
      }
      return type
    },
    gendata(data){
      return {
        title: undefined,
        childrenData: data?.signatures?.map(item2 => {
          return {
            // eslint-disable-next-line
            title: `${isZh() ? item2.descripzhcn : item2.description} (${t('report.analyse.indicator.marks', [item2.marks.length])})`,
            desc: isZh() ? item2.descripzhcn : item2.description,
            type: item2.severity === 1 ? 'low' : (item2.severity === 2 ? 'middle' : 'high'),
            severity: item2.severity,
            childrenData: {
              marks: item2.marks,
              attackid: item2.attackid
            }
          }
        })
      }
    }
  },
  mounted() {
  },
  computed: {
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';
  .detail-name {
    margin-top: -10px;
    height:48px;
    display: flex;
    align-items: center;
    box-shadow: 0 1px 0 0 var(--color-border-2);
    color: var(--color-text-1);
    display: flex;
    align-items: center;
    .detail-icon {
      margin-right: 4px;
    }
    .item-icon {
      padding-right: 10px;
      color:var(--color-text-3);
      width: 18px;
      height: 18px;
    }
    span {
      font-size: 14px;
      color: var(--color-text-1);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: 18px;
    }
  }
  .clearfix::after {
    content: "";
    display: table;
    clear: both;
  }
  .progress-analyse-summary-main {
    border: 1px solid var(--color-border-2);
    margin-bottom: 16px;
  }
  .progress-analyse-summary  {
    display: flex;
    font-size: 14px;
    color: var(--color-text-2);
    &-left  {
      width: 200px;
      flex-shrink: 0;
      border-right: 1px solid var(--color-border-2);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 10px 20px;
      box-sizing: border-box;
      &-chart  {
        position: relative;
        top: 20px;
      }
      &-desc  {
        .green  {
          color: #3EAE89;
        }
        .orange  {
          color: #E69F32;
        }
        .red  {
          color: #CF605B;
        }
      }
    }
    &-right  {
      flex: 1;
      overflow: hidden;
      .summary-content {
        padding: 20px;
        display: flex;
        &-left, &-center, &-right  {
          flex: 1;
          flex-shrink: 0;
          &-item  {
            .content-label  {
              display: inline-block;
              width: 90px;
              color: var(--color-text-3);
            }
            &:last-child:not(:only-child)  {
              margin-top: 10px;
            }
          }
        }
        &>div:not(:first-child)  {
          border-left: 1px solid var(--color-border-2);
          padding-left: 20px;
        }
      }
      .summary-parent  {
        padding: 20px;
        display: flex;
        border-top: 1px solid var(--color-border-2);
        &-left, &-right  {
          min-width: 220px;
          flex-shrink: 0;
          &-item  {
            display: flex;
            .parent-label  {
              display: inline-block;
              width: 90px;
              color: var(--color-text-3);
              &-en {
                width: 200px;
              }
            }
            .parent-value {
              flex: 1;
              white-space: pre-wrap;
              word-break: break-all;
              overflow-wrap: break-word;
            }
            &:last-child:not(:only-child)  {
              margin-top: 10px;
            }
          }
        }
        &-right {
          flex: 3 !important;
        }
        &>div:not(:first-child)  {
          padding-left: 60px;
        }
      }
      .summary-footer  {
        padding: 0 20px 16px;
        & > [class^='summary-footer-']  {
          display: flex;
          &:not(:last-child)  {
            margin-bottom: 10px;
          }
          .footer-label  {
            display: inline-block;
            width: 90px;
            line-height: 20px;
            color: var(--color-text-3);
            flex-shrink: 0;
          }
          .footer-value  {
            line-height: 20px;
            word-break: break-all;
          }
        }
      }
    }
  }
  .card-behavior {
    border-top: 1px solid var(--color-border-2);
    padding: 16px 16px 6px 16px;
  }
  .progress-analyse-events  {
    margin-top: 30px;
    .progress-analyse-events-header  {
      // @include ft_color(color-main-font);
      height: 16px;
      line-height: 16px;
      font-size: 16px;
      font-weight: 500;
      margin-right: 30px;
      border-left: 4px solid;
      // @include bd_color(color-main-highlight);
      padding-left: 6px;
      margin-bottom: 10px;
    }
    .progress-analyse-events-list  {
      padding: 10px 20px;
    }
  }
</style>
