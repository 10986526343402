<template>
  <div class="network-behavior-wrap">
    <Widgest :isEmpty="!total && !loading" :loading="loading" :widgestType="'outer'" :isBig="true">
      <template v-slot:title>
        <img class="icon" :src="$img('search/network.svg')" alt="">
        <span>网络摘要</span>
        <span class="count">{{total}}</span>
        <Spinner v-if="loading" class="loading"/>
        <el-tooltip class="item" :effect="$isDark() ? 'dark' : 'light'" :content="'查看更多'" placement="top">
          <img v-if="total" @click.stop="emit('network')" :src="$img('search/more.svg')" alt="" class="more">
        </el-tooltip>
      </template>
      <SandTab ref="tab" key="overview" :data="tabData" class="network-table" @change="_handleTabChange"></SandTab>
      <sand-network :loading="loading" type="overview" :networkData="networkData" :key="type" :taskid="taskId" :tab="tab" :header="columnData[tab]" :totalCount="networkCount[tab]"></sand-network>
    </Widgest>
  </div>
</template>

<script>
// 分析概览 及 网络分析 的 网络分析模块
import SandTab from './components/sandTab'
import SandNetwork from './components/sandNetwork.vue'
import Widgest from '../../widgest.vue'
import Spinner from '@/components/common/Spinner.vue'
import emitter from '@/app/emitter.js'

import { isZh, t } from 'app/i18n'
export default {
  name: 'StaticInfo',
  components: {
    SandTab,
    SandNetwork,
    Widgest,
    Spinner
  },
  props: {
    networkCount: {
      type: Object,
      default: () => ({
        hosts: 2,
        dns: 5
      })
    },
    taskId: {
      type: String
    },
    type: {
      type: String
    },
    networkData: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    total() {
      return Object.values(this.networkCount).reduce((sum, value) => sum + value, 0)
    },
    columnData () {
      const overviewColumnData = {
        hosts: [
          {
            prop: 'ip',
            label: 'IP地址',
            align: 'left'
          },
          {
            prop: 'country',
            label: '国家或地区',
            align: 'left'
          },
          {
            prop: 'city',
            label: '城市',
            align: 'left'
          },
          {
            prop: 'isp',
            label: 'ISP',
            align: 'left'
          }
        ],
        dns: [
          {
            prop: 'area_name',
            label: '域名',
            align: 'left'
          },
          {
            prop: 'analyse',
            label: '解析结果',
            align: 'left'
          }
        ],
        http: [
          {
            prop: 'uri',
            label: 'URI',
            align: 'left',
            col: 8
          }
        ],
        https: [
          {
            prop: 'uri',
            label: 'URI',
            align: 'left',
            col: 8
          }
        ]
      }
      for (const k in overviewColumnData) {
        overviewColumnData[k].map(v => {
          if (!(k === 'http' || k === 'https')) {
            v.label = t(`report.network.${k}.${v.prop}`)
          }
          return v
        })
      }
      return overviewColumnData
    },
    tabData () {
      const keys = Object.keys(this.columnData)
      const cn = {
        hosts: '主机',
        dns: '域名'
      }
      const resultData = keys.map(item => {
        let label = `${item.toUpperCase()}(${this.networkCount[item]})`
        for (const key in cn) {
          if (key === item && isZh()) {
            label = `${cn[key]}(${this.networkCount[item]})`
          }
        }
        return {
          label,
          name: item
        }
      })
      return resultData
    }
  },
  data () {
    return {
      tab: 'hosts'
    }
  },
  methods: {
    _handleTabChange (tab) {
      this.tab = tab
    },
    emit (type) {
      emitter.emit('ad-tabChange', type)
    }
  }
}
</script>

<style lang="scss" scoped>
// 点击进入网络分析再返回 网络概要有active状态
.network-behavior-wrap {
  :deep(.sand-tableCollapse__header .font) {
    color: var(--color-text-2);
  }
  :deep(.sand-tableCollapse__header) {
    width: calc(100% - 51px) !important;
  }
  :deep(.sandNetwork-wrap .sand-tableCollapse__header--tags) {
    margin-left: 10px;
  }
  :deep(.icon) {
    width: 18px;
    height: 18px;
    margin-right: 4px;
    position: relative;
    top: 3px;
  }
  :deep(.count) {
    width: 22px;
    height: 22px;
    margin-left: 8px;
    justify-content: center;
    align-items: center;
    padding: 0px 6px;
    border-radius: 4px;
    background: var(--color-bg-3);
    color: var(--color-text-3);
  }
  :deep(.more) {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 16px;
    top: 16px;
    &:hover {
       filter: brightness(0) saturate(100%) invert(58%) sepia(48%) saturate(451%) hue-rotate(109deg) brightness(94%) contrast(87%);
    }
  }
  :deep(.loading) {
    position: absolute;
    right: 50px;
    top: 13px;
  }
}
</style>
