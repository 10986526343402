<template>
  <div class="dropped-card" v-for="(currentItem, index) in droppedData" :key="index">
    <div class="detail-name">
      <img :src="$img('search/dll.svg')" alt="">
      <div class="detail-name-right">
        <span class="name" v-copy="`${type === 'dropped' ? 'dropped_name' : 'memdump_md5'}`">{{type === 'dropped' ? currentItem.name : currentItem.md5}}</span>
        <div class="des">
          <span>{{currentItem?.qex_type}}</span>
          <span>{{ `${currentItem.size} ${$t('suffix.byte')}` }}</span>
          <span v-html="scoreType(currentItem?.zserver)"></span>
        </div>
      </div>
    </div>
    <div class="dropped-card-content">
      <div class="dropped-card-contentTop">
        <div>
          <span class="label">MD5</span>
          <span class="value" v-copy="`${type}_md5`">{{currentItem.md5}}</span>
        </div>
        <div>
          <span class="label">SHA1</span>
          <span class="value" v-copy="`${type}_sha1`">{{currentItem.sha1}}</span>
        </div>
        <div>
          <span class="label">SHA256</span>
          <span class="value" v-copy="`${type}_sha256`">{{currentItem.sha256}}</span>
        </div>
        <div>
          <span class="label">SSDeep</span>
          <span class="value" v-copy="`${type}_ssdeep`">{{currentItem.ssdeep}}</span>
        </div>
        <div v-if="type === 'dropped'" style="display: flex;">
          <div class="label">{{$t('report.release.filepath')}}</div>
          <div class="value" v-copy="'dropped_filepath'">{{currentItem.filepath}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isDark, getColor } from 'app/style'
import { t } from 'app/i18n'

export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    droppedData: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'dropped'
    }
  },
  methods: {
    scoreType(zserver) {
      const score = zserver && zserver.threat_level
      let obj = {}
      switch (score) {
        case 70:
          obj = { name: t('risk.malice'), type: 'red', color: '#ff6a63', bgColor: '#ff76701a' }
          break
        case 50:
          obj = { name: t('risk.suspicious'), type: 'orange', color: '#d9b12f', bgColor: '#c2aa6f1a' }
          break
        case 40:
          obj = { name: t('risk.not_detected'), type: '', color: '#7E7F86', bgColor: getColor('--color-bg-3') }
          break
        case 10:
          obj = { name: t('risk.safe'), type: 'green', color: '#1fbe64' }
          break
        default:
          obj = { name: t('risk.not_detected'), type: '', color: isDark() ? '#b8bbcc' : '#525966' }
          break
      }
      return `<span
          style="
            border: 1px solid ${obj.color};
            background-color: ${obj.bgColor};
            display: inline-block;
            font-size: 12px;
            padding: 0 10px;
            text-align: center;
            border-radius: 2px;
            color: ${obj.color};
            line-height: 20px;
        ">${obj.name}</span>`
    }
  }
}
</script>

<style lang="scss" scoped>
.dropped-card {
  .detail-name {
    display: flex;
    margin-bottom: 4px;
    img {
      margin-right: 12px;
    }
    &-right {
      .name {
        font-size: 14px;
        font-weight: 500;
        color: var(--color-text-2);
      }
      .des span {
        font-size: 12px;
        color: var(--color-text-3);
        margin-right: 8px;
      }
    }
  }
  &-content {
    // height: 270px;
    border-radius: 2px;
    border: 1px solid var(--color-border-2);
    padding: 12px 16px;
    &Top {
      // border-bottom: 1px solid var(--color-border-2);
      // padding-bottom: 12px;
      line-height: 2;
      .label {
        color: var(--color-text-3);
        width: 80px;
        display: inline-block;
      }
      .value {
        font-size: 14px;
        color: var(--color-text-2);
      }
    }
    &Bottom {
      padding: 12px 0;
    }
  }
}
</style>
