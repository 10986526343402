<template>
  <div>
    <SandTab ref="tab" :data="tabData" key="network-tab" class="network-table" @change="_handleTabChange"></SandTab>
    <sand-network :loading="loading" :key="type" type="network" :record_id="record_id" :tab="tab" :header="columnData[tab]" :totalCount="networkCount[tab]"></sand-network>
  </div>
</template>

<script>
// 分析概览 及 网络分析 的 网络分析模块
import SandTab from '../analyse/module/components/sandTab'
import SandNetwork from './sandNetwork.vue'

import { isZh, t } from 'app/i18n'
export default {
  name: 'StaticInfo',
  components: {
    SandTab,
    SandNetwork
  },
  props: {
    record_id: {
      type: String
    },
    type: {
      type: String
    },
    networkData: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    columnData () {
      const overviewColumnData = {
        hosts: [
          {
            prop: 'ip',
            label: t('report.network.hosts.ip'),
            align: 'left',
            col: 4
          },
          {
            prop: 'country',
            label: t('report.network.hosts.country'),
            align: 'left',
            col: 4
          },
          {
            prop: 'city',
            label: t('report.network.hosts.city'),
            align: 'left',
            col: 4
          },
          {
            prop: 'isp',
            label: 'ISP',
            align: 'left',
            col: 4
          }
        ],
        dns: [
          {
            prop: 'area_name',
            label: t('report.network.dns.area_name'),
            align: 'left',
            col: 4
          },
          {
            prop: 'analyse',
            label: t('report.network.dns.analyse'),
            align: 'left',
            col: 3
          }
        ],
        http: [
          {
            prop: 'uri',
            label: 'URI',
            align: 'left',
            col: 8
          }
        ],
        https: [
          {
            prop: 'uri',
            label: 'URI',
            align: 'left',
            col: 8
          }
        ]
      }
      for (const k in overviewColumnData) {
        overviewColumnData[k].map(v => {
          if (!(k === 'http' || k === 'https')) {
            v.label = t(`report.network.${k}.${v.prop}`)
          }
          return v
        })
      }
      return overviewColumnData
    },
    tabData () {
      const keys = Object.keys(this.columnData)
      const cn = {
        hosts: '主机',
        dns: '域名'
      }
      const resultData = keys.map(item => {
        let label = `${item.toUpperCase()}(${this.networkCount[item]})`
        for (const key in cn) {
          if (key === item && isZh()) {
            label = `${cn[key]}(${this.networkCount[item]})`
          }
        }
        return {
          label,
          name: item
        }
      })
      return resultData
    }
  },
  data () {
    return {
      networkCount: window.networkTabCount,
      tab: this.findFirstNonZeroKey(window.networkTabCount)
    }
  },
  methods: {
    _handleTabChange (tab) {
      this.tab = tab
    },
    findFirstNonZeroKey(obj) {
      for (const key in obj) {
        if (obj[key] !== 0) {
          return key
        }
      }
      return 'hosts'
    }
  }
}
</script>

<style lang="scss" scoped>
.network-table {
  padding-top: 10px;
}
</style>
