<template>
  <el-tabs v-if="data.length > 0" class="sand-elTab" :class="{'show_full--report':completed}" v-model="activeName" :before-leave="leave" @tab-click="handleClick">
    <el-tab-pane :name="item.name" v-for="item in data" :key="item.name">
      <template #label>
        <span @mouseenter="hoverName = item.name" @mouseleave="hoverName = ''">
          <!-- <img v-if="item.img"
            :src="(activeName === item.name || hoverName === item.name) ? item.activeImg : item.img"> -->
          <span>{{$t(`report.report_tabs.${item.name}`)}}</span><span v-if="item.count !== undefined">{{ `(${item.count ?? ''})` }}</span>
        </span>
      </template>
      <slot :name="item.name"></slot>
    </el-tab-pane>
    <el-tab-pane name="taskList">
      <template #label>
        <span class="locate" @click.stop="locateTask">
          <img :src="$img('search/locate.svg')" alt="">
          定位到任务
        </span>
      </template>
    </el-tab-pane>
  </el-tabs>
  <sand-no-data v-else></sand-no-data>
</template>
<script>
import sandNoData from '@/components/report/sandNoData'
export default {
  components: {
    sandNoData
  },
  props: {
    data: {
      type: Array,
      default: () => [
      ]
    },
    taskid: {
      type: Number
    },
    completed: {
      type: Boolean
    },
    active: {
      type: String,
      default: ''
    }
  },
  data () {
    console.log(this.data)
    return {
      activeName: this.data.map(item => item.name).indexOf(this.$route.params.type) > -1 ? this.$route.params.type : (this.data[0] && this.data[0].name),
      hoverName: ''
    }
  },
  watch: {
    '$route'() {
      console.log(this.$route.params.type)
      this.activeName = this.$route.params.type || 'analyse'
    },
    active() {
      this.activeName = this.active
    }
  },
  mounted () {
    this.$store.commit('CHANGE_REPORT_TAB', {
      tab: this.activeName
    })
  },
  methods: {
    handleClick (tab) {
      this.$store.commit('CHANGE_REPORT_TAB', {
        tab: tab.props.name
      })
      this.$emit('currentTab', tab.props.name)
    },
    leave(activeName) {
      if (activeName === 'taskList') return false
    },
    locateTask() {
      document.querySelector('.lists').scrollIntoView({
        behavior: 'smooth', // 平滑滚动
        block: 'nearest' // 视口顶部对齐（也可以是 'center', 'end', 'nearest'）
      })
    },
    toReport() {
      if (this.$route.name === 'taskstatus') {
        window.open(`/report/${this.taskid}`, '_blank')
      } else {
        this.$router.push({
          name: 'report', params: { taskId: this.taskid, type: 'analyse' }
          // name: 'refresh',
          // query: { taskId: this.taskid, type: 'analyse' }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .sand-elTab{
   & :deep(.el-tabs__header) {
    .el-tabs__item  {
      width: auto !important;
      padding: 0 16px !important;
      font-size: 14px !important;
    }
   }
  }
.sand-elTab  {
  position: sticky;
  top: 0;
  & :deep(.el-tabs__header) {
    margin: 0;
    & .el-tabs__active-bar  {
      height: 0px;
    }
    & .el-tabs__nav  {
      width: 100%;
      background: var(--color-bg-3);
      height: 48px;
      padding-left: 4px;
      box-sizing: border-box;
      display: flex;
    }
    .el-tabs__nav-wrap::after {
      height: 0;
    }
    & .el-tabs__item  {
      width: 128px;
      height: 48px;
      text-align: center;
      font-size: 16px;
      padding: 0;
      font-weight: 400;
      margin-top: 4px;
      &:hover  {
        color: #00cc92;
      }
      &:focus.is-active.is-focus:not(:active)  {
        outline: none;
        box-shadow: none;
      }
      &.is-active  {
        // font-weight: 500;
        border-radius: 2px 2px 0px 0px;
        color: #00cc92;
        background-color: var(--color-bg-1);
        height: 44px;
      }
      & > span  {
        height: 44px;
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 2px;
        & > span  {
          vertical-align: middle;
        }
        & > img  {
          margin-right: 10px;
          vertical-align: middle;
        }
      }
      &:last-child {
        position: absolute;
        right: 12px;
        font-size: 12px !important;
        color: #00cc92;
        &:hover {
          filter: brightness(0) saturate(100%) invert(58%) sepia(48%) saturate(451%) hue-rotate(109deg) brightness(94%) contrast(87%);
        }
        .locate {
          color: var(--color-text-1);
        }
        img {
          width: 16px;
        }
      }
    }
  }
  & :deep(.el-tabs__content)  {
    overflow: visible;
  }
}

[data-theme='light'] {
  .sand-elTab {
    & :deep(.el-tabs__header) {
      & .el-tabs__item {
        &.is-active {
          color: #00ab7a;
        }
        &:hover {
          color: #00ab7a;
        }
      }
    }
  }
}
</style>
