<template>
  <div class="content">
    <div v-if="!loading && !showErrPage">
      <div class="search-wrap">
        <template v-if="isEmpty">
          <img class="search-wrap-img" src="@/assets/img/search/search-not-found.png">
          <!-- <div class="search-wrap-text">暂未找到匹配您查询的 {{map[type]}} 的任务，请试试<span @click="$router.push('/detect')">任务提交</span>功能?</div> -->
          <div class="search-wrap-text">
            <i18n-t keypath="taskhub.search.todetect">
              <template #type>{{map[type]}}</template>
              <template #detect>
                <span @click="$router.push('/detect')">{{$t('taskhub.search.detect')}}</span>
              </template>
            </i18n-t>
          </div>
        </template>
        <template v-else>
          <div class="search-wrap-result-tips">
            <span class="search-wrap-result-tips-desc">{{search_type}}</span>
            <!-- <span class="search-wrap-result-tips-info" @click="$router.push('/taskhub/advancedsearch')">
              切换至高级搜索
            </span> -->
          </div>
          <sample-search-detail :data="target" :search_type="type" :task_count="task_count" :total="auth_count"></sample-search-detail>
          <div class="filters">
            <el-button-group class="btns">
              <el-button @click="taskType('all')" :class="{'active': active === 'all'}" type="primary" plain>{{$t('taskhub.search.all')}}</el-button>
              <el-button @click="taskType('public')" :class="{'active': active === 'public'}" type="primary" plain>{{$t('taskhub.search.public')}}</el-button>
              <el-button @click="taskType('history')" :class="{'active': isHistory}" v-if="isLogin" type="primary" plain>{{$t('taskhub.search.history')}}</el-button>
              <el-button v-if="isLogin" :class="{'active': active === 'team'}" type="primary" plain>
                <el-select @change="teamChange" popper-class="detect-operate"  v-model="value" :placeholder="$t('taskhub.search.team')">
                  <el-option
                    v-for="item in teamOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-button>
            </el-button-group>
          </div>
          <div id="filterWrapper"></div>
          <TemplateList
            :columnAttr="columnAttr"
            :dataList="list"
            :isLoading="listLoading"
            :header="isHistory"
            :hasCheckbox="isHistory"
            :customHeader="isHistory"
            class="history-list"
            :page="active"
            @public="handlePublic"
          >
            <template v-if="isHistory" #table_header>
              <div class="history-list-header">
                <div class="history-list-btn-wrap">
                  <button
                    :class="['btn-item', {disable: !canShare}]"
                    @click="handleShare('share', canShare)"
                    v-if="gid >= 2"
                  >{{$t('taskhub.search.set_public')}}</button>
                  <button
                    :class="['btn-item', {disable: !canCancelShare}]"
                    @click="handleShare('unshare', canCancelShare)"
                    v-if="gid >= 2"
                  >{{$t('taskhub.search.set_private')}}</button>
                   <Popover
                    @submit="handleShareTeam"
                    @close="handlePopoverClose"
                    ref="teamPop"
                    :teamIdsOfTask="teamIdsOfTask"
                    @choose="modifyTeamIdsOfTask"
                    :checklist="checklist"
                  >
                  </Popover>
                </div>
              </div>
            </template>
          </TemplateList>
          <Pagination
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
            :totalSize="totalSize"
            :current_page="currentPage"
            customTotal
          />
        </template>
      </div>
    </div>
    <backTo-top isContainer=".router-view__wrap"></backTo-top>
    <Loading :loading="loading" v-if="loading" />
    <div class="close-captch__wrap" v-if="showErrPage">
      <close-captch v-if="!captchDialogVisible" showFooter @showCaptch="showCaptchHandle"></close-captch>
      <captch-dialog :captchDialogVisible="captchDialogVisible" @close="closeCaptchDialogHandle"></captch-dialog>
    </div>
  </div>
</template>

<script>
import closeCaptch from '@/components/common/closeCaptch.vue'
import captchDialog from '@/components/common/captchDialog.vue'
import { getTaskQuery, getTaskOverview } from 'services/search'
import { postTeamShareTask } from 'services/team'
import { postTaskUserShare } from 'services/center'
import { checkLogin } from 'services/auth'
import TemplateList from '@/components/common/TemplateList'
import Pagination from '@/components/common/Pagination'
import sampleSearchDetail from '../component/sample-search-detail'
import Popover from '../history/component/popover'
// import permiseTable from '@/components/common/permiseTable'
import { mapGetters } from 'vuex'
import emitter from '@/app/emitter.js'
import { t } from 'app/i18n'

export default {
  components: {
    TemplateList,
    Pagination,
    sampleSearchDetail,
    Popover,
    closeCaptch,
    captchDialog
  },
  computed: {
    ...mapGetters({
      gid: 'gid',
      isLogin: 'isLogin',
      teamList: 'team/teamList',
      teamId: 'teamtask/teamId'
    }),
    isHistory() {
      return this.active === 'history'
    },
    isEmpty() {
      return Object.keys(this.target).length === 0
    },
    canShare () {
      return this.checklist.some(item => !item.is_public)
    },
    canCancelShare () {
      return this.checklist.some(item => item.is_public)
    },
    disableShareTeam () {
      return this.checklist.length !== 1
    },
    teamIdsOfTask () {
      const taskIdsSharedMap = {}
      this.checklist.map(item => {
        taskIdsSharedMap[item.id] = item.team_id
      })
      return taskIdsSharedMap
    },
    teamOptions() {
      const team = this.teamList.map((item) => {
        return {
          label: item.team_name,
          value: item.team_id
        }
      })
      team.unshift({ label: t('taskhub.search.all_team'), value: 0 })
      return team
    },
    querystr () {
      return this.$route.query.querystr
    },
    search_type() {
      const Map = {
        md5: 'MD5',
        sha1: 'SHA1',
        sha256: 'SHA256',
        domain: t('taskhub.search.domain'),
        url: 'URL',
        other: t('taskhub.search.keyword')
      }
      return `${Map[this.type]}：${this.querystr}`
    },
    showTableRange () {
      return this.gid > 2 ? 12 : (this.gid === 2 ? 6 : 3)
    },
    columnAttr () {
      const keyMap = [
        {
          label: '环境_时间',
          props: 'env_time',
          span: 6
        },
        {
          label: '类型_分值',
          props: 'type_score',
          span: 4
        },
        {
          label: '名称_类型_标签',
          props: 'name_type_tags',
          span: 7
        },
        {
          label: '哈希',
          props: 'hash',
          span: 7
        }
      ]
      const historyKeyMap = [
        {
          label: '环境_时间',
          props: 'env_time',
          span: 5
        },
        {
          label: '类型_分值',
          props: 'type_score',
          span: 3
        },
        {
          label: '名称_类型_标签',
          props: 'name_type_tags',
          span: 5
        },
        {
          label: '哈希',
          props: 'hash',
          span: 7
        },
        {
          label: '公开私有',
          props: 'public',
          span: 2
        }
      ]
      return this.isHistory ? historyKeyMap : keyMap
    },
    filter_type() {
      const map = {
        public: 'public_filter',
        history: 'private_filter',
        team: 'team_filter'
      }
      return map[this.active]
    },
    teamIds() {
      return !this.value ? this.teamList.map(v => v.team_id) : [this.value]
    }
  },
  data () {
    return {
      list: [],
      target: {},
      showErrPage: false,
      captchDialogVisible: false,
      currentType: 'file',
      pageSize: 50,
      totalSize: 0,
      currentPage: 1,
      loading: true,
      value: '',
      active: 'all',
      checklist: [],
      type: 'other',
      map: {
        md5: 'MD5',
        sha1: 'SHA1',
        sha256: 'SHA256',
        domain: t('taskhub.search.domain'),
        url: 'URL',
        other: t('taskhub.search.keyword')
      },
      filter: {},
      listLoading: false,
      task_count: 0,
      auth_count: 0
    }
  },
  methods: {
    showCaptchHandle() {
      this.captchDialogVisible = true
    },
    async closeCaptchDialogHandle(token) {
      this.captchDialogVisible = false
      if (token) {
        this.loading = true
        this.showErrPage = false
        await this.getTaskOverview(token)
        this.getTaskQuery(true)
        const that = this
        this.$emitter.on(`history/checkedRowList`, (list) => {
          that.checklist = list
        })
        emitter.on('search/filterChange', (v) => {
          this.filter = v
          this.getTaskQuery()
        })
      }
    },
    async getLoginStatus() {
      await checkLogin().then((res) => {
        if (!res.is_login) {
          this.showCaptchHandle()
          this.loading = false
          this.showErrPage = true
        } else {
          this.showErrPage = false
        }
      })
    },
    taskType(val) {
      this.active = val
      this.value = ''
      this.getTaskQuery()
    },
    teamChange(val) {
      this.active = 'team'
      this.getTaskQuery()
    },
    handleSizeChange (size) {
      this.pageSize = size
      this.getTaskQuery()
    },
    handleCurrentChange (page) {
      this.currentPage = page
      this.getTaskQuery()
    },
    modifyTeamIdsOfTask(item, val) {
      if (val) {
        for (let i = 0; i < this.checklist.length; i++) {
          this.checklist[i].team_id.push(item.team_id)
        }
      } else {
        // this.checklist.map(v => v.team_id.filter(m => m === item.team_id))
        this.checklist.map(v => {
          v.team_id = v.team_id.filter(m => m !== item.team_id)
        })
      }
    },
    async handlePublic (taskId, isPublic) {
      if (this.gid < 2) return
      await postTaskUserShare({ task_ids: [taskId], is_public: isPublic })
        .then(() => {
          this.$store.commit('app/showMessage', { message: t('action.handle'), duration: 800 })
          this.getTaskQuery(false, false)
        })
    },
    async getTaskOverview(token) {
      const params = {
        query: this.querystr,
        _token: token || undefined
      }
      await getTaskOverview(params).then(res => {
        this.target = res.task_overview
        this.task_count = res.total
        this.auth_count = res.auth_total
        this.currentType = this.target.category
        this.type = res.search_type
      })
    },
    getTaskQuery (needloading = false, needlistLoading = true) {
      if (needloading) this.loading = true
      const params = {
        query: this.querystr,
        task_type: this.active === 'history' ? 'private' : this.active,
        page: this.currentPage,
        size: this.pageSize,
        ...this.filter
      }
      if (this.active === 'team') {
        params.team_ids = this.teamIds
      }
      if (needlistLoading) this.listLoading = true
      getTaskQuery(params)
        .then(res => {
          this.totalSize = res.total
          this.list = res.task_list
          this.loading = false
          this.listLoading = false
        })
        .catch((e) => { console.log(e) })
    },
    handleShareTeam (share, unshare) {
      const taskIds = this.checklist.map(item => item.id)
      postTeamShareTask({ task_ids: taskIds, share, unshare }).then(res => {
        this.$store.commit('app/showMessage', { message: t('action.share'), duration: 800 })
        // this.$refs.teamPop.clear()
        this.$emitter.emit(`${this.page}/resetChecked`)
        this.getTaskQuery(false, false)
      })
    },
    async handleShare (type, status) {
      if (!status) return
      const isShare = type === 'share'
      await postTaskUserShare({ task_ids: this.checklist.map(item => item.id), is_public: isShare })
        .then(res => {
          this.$store.commit('app/showMessage', { message: t('action.handle'), duration: 800 })
          this.$emitter.emit(`${this.page}/resetChecked`)
          this.getTaskQuery()
        })
    },
    async mountHandle() {
      await this.getTaskOverview()
      this.getTaskQuery(true)
      const that = this
      this.$emitter.on(`history/checkedRowList`, (list) => {
        that.checklist = list
      })
      emitter.on('search/filterChange', (v) => {
        this.filter = v
        this.getTaskQuery()
      })
    }
  },
  watch: {
    'isLogin'() {
      this.$store.dispatch('team/getTeamMembers')
    },
    // active: {
    //   handler: function() {
    //     console.log('active')
    //     this.getTaskQuery()
    //     this.handleFIlterOption()
    //   },
    //   immediate: false
    // },
    async '$route'(n, o) {
      if (o.query.querystr !== n.query.querystr && n.name === 'search') {
        await this.getLoginStatus()
        if (!this.showErrPage) {
          await this.getTaskOverview()
          this.getTaskQuery()
        }
      }
    }
  },
  async mounted () {
    if (this.isLogin) this.$store.dispatch('team/getTeamMembers')
    await this.getLoginStatus()
    if (!this.showErrPage) {
      this.mountHandle()
    } else {
      emitter.on('reloadResult', () => {
        this.showErrPage = false
        this.loading = true
        this.mountHandle()
      })
    }
  },
  unmounted() {
    emitter.off('search/filterChange')
  },
  // beforeRouteUpdate (to, from, next) {
  //   try {
  //     setTimeout(() => {
  //       this.getTaskQuery(true)
  //       next()
  //     }, 300)
  //   } catch (e) {
  //     console.log(e)
  //   }
  // },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.active = from.name === 'history' ? 'history' : from.name === 'teamtask' ? 'team' : 'all'
      if (from.name === 'teamtask') {
        vm.value = 0
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.content {
  min-height: calc(100vh - 116px);
  .close-captch__wrap {
    min-height: calc(100vh - 116px);
    display: flex;
    align-items: center;
  }
}
.search-wrap {
  padding: 20px;
  min-height: calc(100vh - 156px);
  display: flex;
  flex-direction: column;
  &-img {
    width: 287px;
    margin: 100px auto 0 auto;
    display: block;
  }
  &-text {
    display: block;
    text-align: center;
    color: var(--color-text-2);
    word-break: break-all;
    position: relative;
    top: -80px;
    span {
      cursor: pointer;
      color: #00ab7a;
    }
  }
  &-result-tips {
    color: var(--color-text-1);
    margin-bottom: 18px;
    font-size: 14px;
    word-break: break-all;
    display: flex;
    justify-content: space-between;
    &-info {
      font-size: 14px;
      color: #00ab7a;
      cursor: pointer;
    }
  }
  .filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    :deep(.public-list-option) {
      top: 0;
    }
  }
  .btns {
    button {
      border: 1px solid var(--color-text-3);
      border-left-color: var(--color-text-3) !important;
      border-right-color: var(--color-text-3) !important;
      background: none;
      color: var(--color-text-2);
      &:nth-child(4) {
        padding: 0;
      }
      &.active {
        color: #00ab7a;
        border: 1px solid #00ab7a !important;
        position: relative;
        z-index: 1;
      }
    }
    :deep(.el-input__wrapper) {
      background: transparent;
      box-shadow: none;
      input {
        &::placeholder {
          color: var(--color-text-2);
        }
      }
    }
  }
  .search-widgest  {
    background: transparent;
  }
  :deep(.template-list)  {
    margin-top: unset;
  }
  .list-pagination  {
    text-align: right;
    margin-top: 20px;
    margin-right: -20px;
  }
}
.history-list-btn-wrap {
  .btn-item {
    width: 88px;
    height: 32px;
    box-sizing: border-box;
    border: none;
    background: transparent;
    border-radius: 4px;
    color: var(--color-priamry);
    cursor: pointer;
    &:focus {
      outline: 0;
    }
    &:not(:last-child) {
      margin-right: 8px;
    }
    &.disable {
      color: var(--color-text-2);
      cursor: not-allowed;
    }
  }
}
</style>
