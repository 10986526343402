<template>
  <div class="process-behavior-wrap">
    <Widgest :isEmpty="!processData.length" :loading="loading" :widgestType="'outer'" :isBig="true">
      <template v-slot:title>
        <img class="icon" :src="$img('search/process.svg')" alt="">
        <span>进程摘要</span>
        <Spinner v-if="loading" class="loading"/>
        <span class="count">{{moduleCount.process}}</span>
        <el-tooltip class="item" :effect="$isDark() ? 'dark' : 'light'" :content="'查看更多'" placement="top">
          <img v-if="moduleCount.process" @click.stop="emit('process')" :src="$img('search/more.svg')" alt="" class="more">
        </el-tooltip>
      </template>
      <div v-if="!loading">
        <sand-no-data style="padding: 12px 0;" v-if="!moduleCount.process" :isFullscreen="false"/>
        <Authorization
          v-else
          :gid="gid"
          authModule="network-module"
          :hasPayment="hasPermission"
          class="small"
          :showPoint="false"
          fullScreen
        >
          <template v-slot:payment>
            <process-card :processData="processData"/>
          </template>
        </Authorization>
      </div>
    </Widgest>
  </div>
</template>

<script>
import Widgest from '../../widgest.vue'
import processCard from './components/processCard.vue'
import Spinner from '@/components/common/Spinner.vue'
import sandNoData from '@/components/report/sandNoData'
import { mapGetters, mapState } from 'vuex'
import emitter from '@/app/emitter.js'

export default {
  name: 'process',
  components: {
    Widgest,
    processCard,
    Spinner,
    sandNoData
  },
  props: {
    processData: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    },
    hasPermission: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['gid']),
    ...mapState({
      moduleCount: (state) => state.search.moduleCount
    })
  },
  methods: {
    emit (type) {
      emitter.emit('ad-tabChange', type)
    }
  }
}
</script>

<style lang="scss" scoped>
.process-behavior-wrap {
  :deep(.sand-tableCollapse__header .font) {
    color: var(--color-text-2);
  }
  :deep(.sand-tableCollapse__header) {
    width: calc(100% - 51px) !important;
  }
  :deep(.sandNetwork-wrap .sand-tableCollapse__header--tags) {
    margin-left: 10px;
  }
  :deep(.icon) {
    width: 18px;
    height: 18px;
    margin-right: 4px;
    position: relative;
    top: 3px;
  }
  :deep(.count) {
    width: 22px;
    height: 22px;
    margin-left: 8px;
    justify-content: center;
    align-items: center;
    padding: 0px 6px;
    border-radius: 4px;
    background: var(--color-bg-3);
    color: var(--color-text-3);
  }
  :deep(.more) {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 16px;
    top: 16px;
    &:hover {
       filter: brightness(0) saturate(100%) invert(58%) sepia(48%) saturate(451%) hue-rotate(109deg) brightness(94%) contrast(87%);
    }
  }
  :deep(.loading) {
    position: absolute;
    right: 50px;
    top: 13px;
  }
}
</style>
