<template>
  <div>
    <sand-el-tab
      class="report-tab"
      :data="data"
      @currentTab="handleTabChange"
      :active="currentModule"
    >
    </sand-el-tab>
    <keep-alive>
      <component
        class="contentWrapper"
        :is="tabs[currentModule]"
        :count="networkTabCount"
        :record_id="props.record_id"
        :total="total"
        :key="currentModule"
      >
      </component>
    </keep-alive>
  </div>
</template>

<script setup>
import { ref, defineProps, computed, defineEmits } from 'vue'
import sandElTab from './components/sandElTab'
import analyse from './components/analyse/index.vue'
import network from './components/network'
import process from './components/process'
import event from './components/event'
import dropped from './components/dropped'
import memdump from './components/memdump'
// import process from './components/process/index.vue'
import { getAdSearchModuleCount } from 'services/search'
import emitter from '@/app/emitter.js'
import { useStore } from 'vuex'

const store = useStore()
const props = defineProps({
  record_id: {
    type: Number,
    default: 0
  }
})
const emits = defineEmits(['toggleList'])
const currentModule = ref('task')
const tabs = {
  task: analyse,
  network,
  process,
  event,
  dropped,
  memdump
}
emitter.on('ad-tabChange', (v) => {
  handleTabChange(v)
})
function handleTabChange(tab) {
  document.querySelector('.explain').scrollTo(0, 0)
  currentModule.value = tab
}
const networkTabCount = ref({
  hosts: 0,
  dns: 0,
  http: 0,
  https: 0
})
const total = computed(() => {
  if (currentModule.value === 'taskList') return
  const item = data.value.find(v => v.name === currentModule.value)
  if (currentModule.value === 'event') {
    return item.event_info_count
  }
  return item.count
})

getAdSearchModuleCount({
  record_id: props.record_id
}).then(res => {
  data.value = data.value.map(item => {
    item.count = res[item.alias || item.name]
    if (item.name === 'event') {
      item.event_info_count = res.event_info
    }
    return item
  })
  const isEmpty = Object.values(data.value).reduce((sum, value) => {
    return sum + (value.count || 0)
  }, 0) === 0
  setTimeout(() => {
    emits('toggleList', !isEmpty)
  }, 200)

  networkTabCount.value.hosts = res.network_hosts || 0
  networkTabCount.value.dns = res.domains || 0
  networkTabCount.value.http = res.http_info || 0
  networkTabCount.value.https = res.https_info || 0
  window.networkTabCount = networkTabCount.value
  const obj = data.value.reduce((acc, item) => {
    acc[item.name] = item.count || 0
    return acc
  }, {})
  obj.event_info = res.event_info || 0
  window.tabCount = obj
  store.commit('search/setModuleCount', obj)
})
const data = ref([
  {
    name: 'task',
    label: '分析概览'
  },
  {
    name: 'network',
    label: '网络分析',
    count: 1
  },
  {
    name: 'process',
    alias: 'behavior_info',
    label: '进程',
    count: 0
  },
  {
    name: 'event',
    label: '事件',
    count: 0,
    event_info_count: 0
  },
  {
    name: 'dropped',
    label: '释放文件',
    alias: 'dropped_info'
  },
  {
    name: 'memdump',
    label: '释放内存',
    alias: 'memdump_info',
    count: 0
  }
])

</script>
<style lang="scss" scoped>
.report-tab {
  position: sticky;
  top: 0;
  z-index: 998;
}
.contentWrapper {
  // padding-bottom: 20px;
  // overflow-y: scroll;
}
</style>
