<template>
  <div v-for="(currentItem, index) in eventData" :key="index">
    <div class="event-card">
      <div class="detail-name">
        <img v-if="isDetail" class="detail-icon" :src="$img('search/event.svg')" alt="">
        <span v-copy="'process_name'">{{currentItem.process_name}}</span>
      </div>
      <div class="event-card-content">
        <div class="event-card-contentTop">
          <div>
            <span class="label">进程 ID</span>
            <span class="value">{{currentItem.pid}}</span>
          </div>
          <div>
            <span class="label">进程路径</span>
            <span class="value" v-copy="'process_path'">{{currentItem.process_path}}</span>
          </div>
          <div>
            <span class="label">命令行</span>
            <span class="value" v-copy="'command_line'">{{currentItem.command_line}}</span>
          </div>
        </div>
        <div class="event-card-contentBottom">
          <BehaviorEvents :events="genData(currentItem.events)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BehaviorEvents from '@/views/report/BehaviorEvents'

export default {
  components: {
    BehaviorEvents
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    eventData: {
      type: Array,
      default: () => []
    },
    isDetail: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    events() {
      return [{
        event: this?.eventData[0]?.events[0]
      }]
    }
  },
  methods: {
    genData(events) {
      // console.log('[ event ] >', JSON.parse(JSON.stringify(events)))
      return events.map(v => {
        return {
          event: v
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-name {
  margin-top: -10px;
  height:48px;
  display: flex;
  align-items: center;
  color: var(--color-text-1);
  .item-icon {
    padding-right: 10px;
    color:var(--color-text-3);
    width: 18px;
    height: 18px;
  }
  .detail-icon {
    margin-right: 4px;
  }
  span {
    font-size: 14px;
    color: var(--color-text-1);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 18px;
  }
}
.event-card {
  &-content {
    // height: 270px;
    border-radius: 2px;
    border: 1px solid var(--color-border-2);
    padding: 16px;
    &Top {
      border-bottom: 1px solid var(--color-border-2);
      padding-bottom: 12px;
      line-height: 2;
      & > div {
        display: flex;
      }
      .label {
        color: var(--color-text-3);
        width: 80px;
        display: inline-block;
      }
      .value {
        flex: 1;
        font-size: 14px;
        color: var(--color-text-2);
        white-space: pre-wrap;
        word-break: break-all;
        overflow-wrap: break-word;
      }
    }
    &Bottom {
      padding: 12px 0;
    }
  }
}
</style>
