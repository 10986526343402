import request from 'app/http'
import qs from 'qs'

// const getTaskQuery = (params) => {
//   return request.get(`/task/search?query=${params}`)
// }

const getTaskQuery = (params) => {
  return request.get('/task/common/search', {
    params,
    paramsSerializer: params => {
      return qs.stringify(params, {
        indices: false
      })
    }
  })
}

const getTaskOverview = (params) => {
  return request.get('/task/common/search/overview', { params })
}

const postAdSearch = (params) => {
  return request.post('/adsearch/record', params)
}
const getAdSearchStatus = (params) => {
  return request.get('/adsearch/record', { params })
}
const getAdSearchList = (params) => {
  return request.get('/adsearch/records', { params })
}
const getAdSearchReports = (params) => {
  return request.post('/adsearch/reports', params)
}
const deleteSearchRecord = (params) => {
  return request.delete('/adsearch/record', { data: params })
}
const getAdSearchModuleCount = (params) => {
  return request.get('/adsearch/module/statistics', { params })
}
const getAdSearchModuleOverview = (params) => {
  return request.get('/adsearch/module/overview', { params })
}
const getAdSearchModuleDetail = (params) => {
  return request.get('/adsearch/module/module', { params })
}
export {
  getTaskQuery,
  getTaskOverview,
  postAdSearch,
  getAdSearchStatus,
  getAdSearchList,
  getAdSearchReports,
  deleteSearchRecord,
  getAdSearchModuleCount,
  getAdSearchModuleOverview,
  getAdSearchModuleDetail
}
