<template>
  <div class="sample-search_detail">
    <div class="sample-search_detail_left"><dashboard :noReport="!task_count" :combined="data.max_score"></dashboard></div>
    <div class="sample-search_detail_center">
      <p v-if="search_type === 'domain'"><span>{{$t('taskhub.search.domain')}}</span>{{$route.query.querystr}}</p>
      <p v-else-if="search_type === 'url'"><span>URL</span>{{$route.query.querystr}}</p>
      <template v-else>
        <p><span>MD5</span>{{data.md5}}</p>
        <p><span>SHA1</span>{{data.sha1}}</p>
        <p><span>SHA256</span>{{data.sha256}}</p>
        <p><span>SSDEEP</span>{{data.ssdeep || '-'}}</p>
        <p><span>{{$t('taskhub.search.filesize')}}</span>{{getSize(data.file_size)}}</p>
        <p><span>{{$t('taskhub.search.format')}}</span>{{data.file_format|| '-'}}</p>
        <p><span>CRC32</span>{{data.crc32|| '-'}}</p>
      </template>
    </div>
    <div class="sample-search_detail_right">
      <div class="icon">
        <div class="platform">
          <svg-icon :name="data.os || 'windows'"></svg-icon>
          <p>{{data.os || 'windows'}}</p>
        </div>
        <div class="type">
          <img :src="filetypeIcon(data)" alt="" />
          <p>{{ search_type === 'domain' ? $t('taskhub.search.domain') : search_type === 'url' ? 'URL' : data.file_type }}</p>
        </div>
      </div>
      <div class="sample-search-right-info">
        <p><span>{{$t('taskhub.search.earliest')}}</span>{{data.earliest_detect_time}}</p>
        <p><span>{{$t('taskhub.search.latest')}}</span>{{data.latest_detect_time}}</p>
        <p><span>{{$t('taskhub.search.task_num')}}</span>{{task_count}}</p>
        <p><span>{{$t('taskhub.search.viewed_num')}}</span>{{total}}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, toRefs } from 'vue'
import dashboard from 'components/echarts/dashboard'
import { t } from 'app/i18n'

const props = defineProps({
  data: {
    type: Object,
    require: true
  },
  search_type: String,
  task_count: Number,
  total: Number
})

const { data, total, search_type, task_count } = toRefs(props)

// function getOSIcon(os, env) {
//   const os2 = os !== 'linux' ? os : env[0].toLowerCase()
//   const keyMap = {
//     ubuntu: 'ubuntu-big',
//     linux: 'linux-big',
//     android: 'android-big',
//     windows: 'windows-big'
//   }
//   const type = keyMap[os2] ? keyMap[os2] : 'windows-big'
//   return require(`@/assets/img/svg/${type}.svg`)
// }

function getSize(size) {
  if (size === '') return '-'
  if (size < 1024) {
    return `${size}${t('byte')}`
  }
  if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(1)}K`
  }
  return `${(size / 1024 / 1024).toFixed(1)}M`
}

function filetypeIcon(row) {
  if (row.os === 'android') {
    return require('@/assets/img/svg/filetype/apk.svg')
  }
  const type = row.file_type
  let file = ''
  if (row.category === 'file' || type) {
    const list = [
      'html',
      'hwp',
      'js',
      'mhtml',
      'powershell',
      'python',
      'rar',
      'rtf',
      'swf',
      'VBS-icon',
      'email'
    ]
    if (list.includes(type)) {
      file = type
    } else if (type === 'doc' || type === 'docx') {
      file = 'doc'
    } else if (type === 'xls' || type === 'xlsx') {
      file = 'excel'
    } else if (type === 'dll32' || type === 'dll64') {
      file = 'dll'
    } else if (type === 'exe32' || type === 'exe64' || type === 'nsis_exe') {
      file = 'exe'
    } else if (type === 'ppt' || type === 'pptx') {
      file = 'ppt'
    } else if (type === 'bzip2' || type === 'gzip' || type === 'zip') {
      file = 'zip'
    } else {
      file = 'unknow'
    }
  } else if (row.category === 'url') {
    file = 'IE'
  } else if (row.category === 'md5') {
    file = 'md5'
  }
  return require(`@/assets/img/svg/filetype/${file}.svg`)
}

</script>
<style lang="scss" scoped>
.sample-search_detail {
  width: 100%;
  height: 224px;
  box-sizing: border-box;
  background: var(--detect-config-bg);
  box-shadow: 0 0 10px 0 var(--popover-box-shadow1);
  border-radius: 4px;
  margin-bottom: 16px;
  padding: 20px;
  display: flex;
  & > div {
    height: 100%;
    border-right: 1px solid var(--color-border-2);
  }
  p {
    margin: 0;
    color: var(--color-text-2);
    margin-bottom: 12px;
    span {
      display: inline-block;
      color: var(--color-text-3);
      min-width: 56px;
      margin-right: 12px;
    }
  }
  &_center {
    width: 60%;
    padding: 0 30px;
    box-sizing: border-box;
    p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  &_right {
    // flex: 5;
    width: 25%;
    border: none !important;
    padding: 0 30px;
    min-width: 320px;
    box-sizing: border-box;
    .icon {
      display: flex;
      margin-bottom: 10px;
      & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 60px;
      }
      img {
        width: 30px;
        height: 30px;
        margin-bottom: 10px;
        filter: var(--svg-filter1);
      }
      .platform {
        margin-right: 50px;
        svg {
          font-size: 30px;
          margin-bottom: 10px;
          color: var(--color-text-2);
        }
      }
    }
  }
  &_left {
    min-width: 155px;
    // flex: 2;
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    left: -10px;
  }
}
</style>
