<template>
  <div class="event-behavior-wrap">
    <Widgest :isEmpty="!eventData.length" :loading="loading" :widgestType="'outer'" :isBig="true">
      <template v-slot:title>
        <img class="icon" :src="$img('search/event.svg')" alt="">
        <span>事件摘要</span>
        <Spinner v-if="loading" class="loading"/>
        <span class="count">{{moduleCount.event}}</span>
        <el-tooltip class="item" :effect="$isDark() ? 'dark' : 'light'" :content="'查看更多'" placement="top">
          <img v-if="moduleCount.event" @click.stop="emit('event')" :src="$img('search/more.svg')" alt="" class="more">
        </el-tooltip>
      </template>
      <sand-no-data style="padding: 12px 0;" v-if="!moduleCount.event" :isFullscreen="false"/>
      <Authorization
        v-else
        :gid="gid"
        authModule="event-module"
        :hasPayment="hasPermission"
        class="small"
        :showPoint="false"
        fullScreen
      >
        <template v-slot:payment>
          <event-card v-if="eventData.length" :eventData="eventData"/>
        </template>
      </Authorization>
    </Widgest>
  </div>
</template>

<script>
import Widgest from '../../widgest.vue'
import eventCard from './components/eventCard.vue'
import Spinner from '@/components/common/Spinner.vue'
import { mapGetters, mapState } from 'vuex'
import sandNoData from '@/components/report/sandNoData'
import emitter from '@/app/emitter.js'

export default {
  name: 'StaticInfo',
  components: {
    Widgest,
    eventCard,
    Spinner,
    sandNoData
  },
  props: {
    eventData: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    },
    hasPermission: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['gid']),
    ...mapState({
      moduleCount: (state) => state.search.moduleCount
    })
  },
  methods: {
    emit (type) {
      emitter.emit('ad-tabChange', type)
    }
  }
}
</script>

<style lang="scss" scoped>
.event-behavior-wrap {
  :deep(.sand-tableCollapse__header .font) {
    color: var(--color-text-2);
  }
  :deep(.sand-tableCollapse__header) {
    width: calc(100% - 51px) !important;
  }
  :deep(.sandNetwork-wrap .sand-tableCollapse__header--tags) {
    margin-left: 10px;
  }
  :deep(.icon) {
    width: 18px;
    height: 18px;
    margin-right: 4px;
    position: relative;
    top: 3px;
  }
  :deep(.count) {
    width: 22px;
    height: 22px;
    margin-left: 8px;
    justify-content: center;
    align-items: center;
    padding: 0px 6px;
    border-radius: 4px;
    background: var(--color-bg-3);
    color: var(--color-text-3);
  }
  :deep(.more) {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 16px;
    top: 16px;
    &:hover {
       filter: brightness(0) saturate(100%) invert(58%) sepia(48%) saturate(451%) hue-rotate(109deg) brightness(94%) contrast(87%);
    }
  }
  :deep(.loading) {
    position: absolute;
    right: 50px;
    top: 13px;
  }
}
</style>
