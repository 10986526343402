<template>
  <div class="search-memdump-wrap" v-loading="loading" :element-loading-text="$t('loading')" element-loading-background="transparent">
    <sand-no-data v-if="!moduleCount.memdump && !loading" :isFullscreen="true"/>
    <Authorization
      v-else
      :gid="gid"
      authModule="memdump-module"
      :hasPayment="hasPermission"
      :showPoint="false"
      fullScreen
    >
      <template v-slot:payment>
        <div class="search-release">
        <ReleaseMemory  releaseOrMendump="memdump" id="releasefile" v-if="releasefile && !loading" type="adSearch" :releasefile="releasefile" />
        <div class="pagination-wrap" v-if="!loading">
          <Pagination
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
            :totalSize="totalSize"
            :current_page="currentPage"
            :pageSizes="[10, 50]"
            :page-size="pageSize"
            customTotal
          />
        </div>
      </div>
      </template>
    </Authorization>
  </div>
</template>

<script>
import { getAdSearchModuleDetail } from 'services/search'
import Pagination from '@/components/common/Pagination'
import ReleaseMemory from '@/views/report/ReleaseMemory'
import { isZh } from 'app/i18n'
import sandNoData from '@/components/report/sandNoData'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'release',
  components: {
    ReleaseMemory,
    Pagination,
    sandNoData
  },
  props: {
    record_id: {
      type: String,
      required: true
    },
    total: {
      type: Number
    }
  },
  data() {
    return {
      releasefile: {},
      loading: true,
      currentPage: 1,
      totalSize: this.total,
      pageSize: 10,
      hasPermission: true
    }
  },
  computed: {
    ...mapGetters(['gid']),
    ...mapState({
      moduleCount: (state) => state.search.moduleCount
    }),
    taskId() {
      return this.$route.params.taskId
    }
  },
  methods: {
    async init() {
      this.getList()
    },
    async getList() {
      document.querySelector('.explain').scrollTo({
        top: 0
      })
      this.loading = true
      getAdSearchModuleDetail({
        page: this.currentPage,
        size: this.pageSize,
        protocol: 'memdump_info',
        record_id: this.record_id,
        lang: isZh() ? 'zh' : 'en'
      }).then(res => {
        this.loading = false
        this.initial = false
        if (res.code === 4020) {
          this.hasPermission = false
          return
        }
        this.releasefile.dropped = res
      })
    },
    handleSizeChange (size) {
      this.pageSize = size
      this.getList()
    },
    handleCurrentChange (page) {
      this.currentPage = page
      this.getList()
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.search-release {
  min-height: 178px;
  :deep(.release-file-wrap) {
    padding-top: 8px;
  }
}
.search-memdump-wrap > :deep(.sand-noData) {
  // height: calc(100vh - 305px) !important;
  padding: 56px 0;
  height: auto !important;
}
:deep(.sand-noData) {
  // height: calc(100vh - 385px) !important;
  padding: 56px 0;
  height: auto !important;
}
.pagination-wrap {
  padding: 0 0 12px;
  margin-right: -20px;
}
</style>
