<template>
  <div class="event-wrapper" v-loading="loading" :element-loading-text="$t('loading')" element-loading-background="transparent">
    <sand-no-data v-if="!moduleCount.event && !loading" :isFullscreen="true"/>
    <Authorization
      :gid="gid"
      authModule="event-module"
      :hasPayment="hasPermission"
      class="indicator"
      :showPoint="false"
      fullScreen
      v-else
    >
      <template v-slot:payment>
        <event-card :isDetail="true" v-if="eventData.length && !loading" :eventData="eventData"/>
        <div class="pagination-wrap" v-if="moduleCount.event && !loading">
          <Pagination
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
            :totalSize="count"
            :current_page="currentPage"
            :page-size="pageSize"
            :pageSizes="[10, 50]"
            customTotal
          />
        </div>
      </template>
    </Authorization>
  </div>
</template>

<script setup>
import { ref, defineProps } from 'vue'
import { getAdSearchModuleDetail } from 'services/search'
import eventCard from '../analyse/module/components/eventCard'
import sandNoData from '@/components/report/sandNoData'
import { useState, useGetters } from '@/hooks/useVuex'
import Pagination from '@/components/common/Pagination'

const { gid } = useGetters('user', ['gid'])
const { moduleCount } = useState('search', ['moduleCount'])
const currentPage = ref(1)
const pageSize = ref(10)
const loading = ref(true)
const hasPermission = ref(true)
const props = defineProps({
  record_id: {
    type: String,
    required: true
  }
})
const count = ref(window.tabCount.event_info)
const eventData = ref([])
const getList = () => {
  loading.value = true
  document.querySelector('.explain').scrollTo({
    top: 0
  })
  getAdSearchModuleDetail({
    page: currentPage.value,
    size: pageSize.value,
    protocol: 'event_info',
    record_id: props.record_id
  // lang: isZh() ? 'zh' : 'en'
  }).then(res => {
    loading.value = false
    if (res.code === 4020) {
      hasPermission.value = false
      return
    }
    eventData.value = [...res]
  })
}
getList()
const handleSizeChange = (size) => {
  pageSize.value = size
  getList()
}
const handleCurrentChange = (page) => {
  currentPage.value = page
  getList()
}
</script>
<style lang="scss" scoped>
.event-wrapper {
  // min-height: calc(100vh - 305px);
  min-height: 178px;
  :deep(.detail-name) {
    margin-top: 0;
  }
  :deep(.sand-noData) {
    // height: calc(100vh - 304px);
    height: auto;
    padding: 56px 0;
  }
  :deep(.progress-analyse-summary-main) {
    margin-bottom: 0;
  }
}
.pagination-wrap {
  padding: 12px 0;
  margin-right: -20px;
}
</style>
