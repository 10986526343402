<template>
  <div class="process-wrapper" v-loading="loading" :element-loading-text="$t('loading')" element-loading-background="transparent">
    <Authorization
      v-if="count"
      :gid="gid"
      authModule="process-module"
      :hasPayment="!showAuth"
      class="indicator"
      :showPoint="false"
      fullScreen
    >
      <template v-slot:payment>
        <process-card :isDetail="true" v-if="processData.length && !loading"  :processData="processData"/>
        <div class="pagination-wrap" v-if="count && !loading">
          <Pagination
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
            :totalSize="count"
            :current_page="currentPage"
            :page-size="pageSize"
            :pageSizes="[10, 50]"
            customTotal
          />
        </div>
      </template>
    </Authorization>
    <sand-no-data v-else :isFullscreen="true"/>
  </div>
</template>

<script setup>
import { ref, defineProps } from 'vue'
import { getAdSearchModuleDetail } from 'services/search'
import processCard from '../analyse/module/components/processCard'
import sandNoData from '@/components/report/sandNoData'
import { useGetters } from '@/hooks/useVuex'
import Pagination from '@/components/common/Pagination'

const currentPage = ref(1)
const pageSize = ref(10)
const loading = ref(true)
const props = defineProps({
  record_id: {
    type: String,
    required: true
  },
  total: {
    type: Number,
    required: true
  }
})
const count = ref(window.tabCount.process)
const { gid } = useGetters('user', ['gid'])
const showAuth = ref(false)
const processData = ref([])
const getList = () => {
  document.querySelector('.explain').scrollTo({
    top: 0
  })
  loading.value = true
  getAdSearchModuleDetail({
    page: currentPage.value,
    size: pageSize.value,
    protocol: 'behavior_info',
    record_id: props.record_id
  // lang: isZh() ? 'zh' : 'en'
  }).then(res => {
    loading.value = false
    if (res.code === 4020) {
      showAuth.value = true
      return
    }
    processData.value = [...res]
  })
}
getList()
const handleSizeChange = (size) => {
  pageSize.value = size
  getList()
}
const handleCurrentChange = (page) => {
  currentPage.value = page
  getList()
}
</script>
<style lang="scss" scoped>
.process-wrapper {
  // min-height: calc(100vh - 305px);
  min-height: 178px;
  :deep(.detail-name) {
    margin-top: 0;
  }
  :deep(.sand-noData) {
    // height: calc(100vh - 305px);
    height: auto;
    padding: 56px 0;
  }
  :deep(.progress-analyse-summary-main) {
    margin-bottom: 0;
  }
}
.pagination-wrap {
  padding: 12px 0;
  margin-right: -20px;
}
</style>
