<template>
  <el-collapse class="h2" :disabled="props.loading" v-model="activeNames">
    <el-collapse-item name="static1">
      <template #title>
        <div :class="['widgest-title', {big: isBig}]" v-if="widgestType === 'outer'">
          <slot name="title"></slot>
        </div>
      </template>
      <div class="widgest-wrap" :style="{background: bgColor}">
        <div :class="['widgest-content', widgestType]" ref="widgest">
          <slot></slot>
        </div>
      </div>
    </el-collapse-item>
  </el-collapse>
</template>

<script setup>

import { defineProps, onMounted, ref, defineExpose, watch } from 'vue'

const props = defineProps({
  icon: {
    type: String,
    default () {
      return ''
    }
  },
  styleText: {
    type: Object
  },
  bgColor: {
    type: String
  },
  widgestType: {
    type: String,
    validator (value) {
      return ['inner', 'outer'].indexOf(value) > -1
    },
    default () {
      return 'inner'
    },
    required: false
  },
  isBig: {
    type: Boolean,
    default: false
  },
  isOpenAll: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  },
  isEmpty: {
    type: Boolean,
    default: false
  }
})

const activeNames = ref('static')
const widgest = ref()

const init = () => {
  if (props.styleText) {
    for (const attr in props.styleText) {
      widgest.value.style[attr] = props.styleText[attr]
    }
  }
  if (props.isOpenAll) {
    activeNames.value = 'static1'
  }
}
watch(() => !props.loading, v => {
  if (v && !props.isEmpty) {
    activeNames.value = 'static1'
  }
})
watch(() => props.isEmpty, v => {
  if (!v) {
    activeNames.value = 'static1'
  }
})
defineExpose({
  close: () => {
    activeNames.value = 'static'
  }
})

onMounted(() => {
  init()
})
</script>

<style lang="scss" scoped>
  .h2 {
    border: none;
    margin-top: 10px;
    &:deep(.el-collapse-item__header) {
      // margin: 0 20px;
      border-bottom: 1px solid var(--color-border-2);
      background: var(--detect-config-bg);
      &:hover {
        background-color: var(--detect-config-bg);
      }
      .el-collapse-item__arrow {
        color: var(--color-text-3);
        font-size: 14px;
      }
      .widgest-title {
        width: calc(100% - 20px);
        position: relative;
      }
    }
    &:deep(.el-collapse-item__wrap) {
      border: none;
      transition: none;
      .el-collapse-item__content {
        padding: 0;
      }
    }
  }
  .widgest-wrap {
    overflow: hidden;
    margin-top: 10px;
    position: relative;
    // bg_color(color-widgest-bg);
    // bx_shadow(0, 0, 14px, 2px, color-widgest-shadow);
    .widgest-title {
      display: flex;
      align-items: center;
      font-size: 16px !important;
      font-weight: 500;
      line-height: 44px;
      color: var(--color-text-1);
      box-shadow: 0 1px 0 0 var(--color-border-2);
      margin: 0 20px;
      &.big {
        font-size: 18px !important;
        line-height: 50px;
      }
    }
    .widgest-content {
      // padding: 10px 30px 20px;
      width: 100%;
      // width: calc(100vw - 600px);
      // width: calc(100vw - 612px);
      box-sizing: border-box;
      // padding: 20px 18px;
      &.outer {
        margin-bottom: 4px;
      }
    }
  }
</style>
